import React, { useEffect } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';

import { Col, Row, Layout, Typography } from 'antd';

const { Title } = Typography;

const Home: React.FC = () => {
  const router = useRouter();
  useEffect(() => {
    router.push('center');
  }, []);

  return (
    <Layout
      style={{
        backgroundSize: '100%',
        height: '100vh',
      }}
    >
      <Head>
        <title>Center</title>
      </Head>

      <Row justify="end" align="bottom" style={{ height: '100%' }}>
        <Col span={8}>
          <Title style={{ color: 'black' }}>
            Estamos trabajando en una nueva experiencia para tí
            <img alt="logo" style={{ marginLeft: '20px', width: '50%' }} src="tj-logo.svg" />
          </Title>
        </Col>
      </Row>
    </Layout>
  );
};

export default Home;
